import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import App from "./App.vue";
import i18n from "./i18n";
import Notifications from "vue-notification";
import { devSetup } from "./dev-settings";

Vue.use(Notifications);

Vue.config.productionTip = false;

let vueLifecycles = {};

if (process.env.VUE_APP_ENVIRONMENT === "dev") {
    (async () => {
        await devSetup();
    })();

    new Vue({
        i18n,
        render: h => h(App)
    }).$mount("#mfe-auth");


    vueLifecycles.bootstrap = () => Promise.resolve();
    vueLifecycles.mount = () => Promise.resolve();
    vueLifecycles.unmount = () => Promise.resolve();

} else {
    vueLifecycles = singleSpaVue({
        Vue,
        appOptions: {
            i18n,
            render(h) {
                return h(App, {
                    props: {
                        // single-spa props are available on the "this" object. Forward them to your component as needed.
                        // https://single-spa.js.org/docs/building-applications#lifecyle-props
                        name: this.name,
                        mountParcel: this.mountParcel,
                        singleSpa: this.singleSpa,
                    },
                });
            },
        },
    });

}

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

