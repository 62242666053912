<template>
    <div id="mfe-auth">
        <notifications :duration="3000" classes="say-simple-notification"/>
        <div class="page-left__logo">
            <div class="slanted-background" />

            <div id="logo" />
        </div>
        <div class="page-right__form">
            <div id="form-auth">
                <h2>{{ $t("login") }}</h2>
                <form @submit.prevent="login" v-if="(!isLogged && allowed)">

                    <div class="field">
                        <label for="domain" v-if="debug">
                            <span>{{ $t("domain") }}</span>
                            <input type="text" id="domain" v-model="domain" :placeholder="$t('domain')"/>
                        </label>

                        <label for="email-address">
                            <span>{{ $t("email_address") }}</span>
                            <input type="email" id="email-address" v-model="username" :placeholder="$t('email_address')"/>
                        </label>
                    </div>

                    <div class="field">
                        <label for="password">
                            <span>{{ $t("password") }}</span>
                            <input type="password" id="password" v-model="password" :placeholder="$t('password')"/>
                        </label>
                    </div>

                    <div class="field">
                        <label for="remember">
                            <input type="checkbox" id="remember" v-model="remember"/> <span>{{ $t('remember') }}</span>
                        </label>
                    </div>

                    <button type="submit">{{ $t("button_login") }}</button>
                    <a href="/inbox/auth/reset-password" class="text-link float-right">Forgot password</a>
                </form>

                <div class="say-simple-error" v-if="!allowed">
                    <h2>{{ $t('oops') }}</h2>
                    <p v-html="$t('no_domain')"/>
                </div>
            </div>
        </div>
        <div id="mfe-auth-debug" v-if="debug">
            <p>{{ isLogged ? "✅ Logged in" : "❌ Not logged in" }}</p>
            <p v-if="invalid" class="invalid">Wrong credentials</p>
            <button type="button" @click="logout">Logout</button>
            <button type="button" @click="showInfo">Info</button>
        </div>
    </div>
</template>

<script>
import * as SaySimpleAuthenticator from "@saysimple/auth-module";
import * as queryString from "query-string";

export default {
    name: "App",
    data() {
        return {
            domain: null,
            username: "",
            password: "",
            isLogged: SaySimpleAuthenticator.getAuthorizer().isValid(),
            remember: window.localStorage.getItem("username"),
            force: false,
            invalid: false,
            debug: false,
            allowed: true,
        };
    },
    async beforeCreate() {
        try {
            SaySimpleAuthenticator.isLoggedIn();
        } catch (e) {
            await SaySimpleAuthenticator.init(
                `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/authenticate`,
                `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/refresh`,
                `${process.env.VUE_APP_APIGATEWAY_ENDPOINT}/auth/expire-token`,
                SaySimpleAuthenticator.getPersistentSession(),
            );
        }
    },
    methods: {
        async login() {

            this.invalid = false;

            try {
                if (this.remember) {
                    window.localStorage.setItem("username", this.username);
                }

                await SaySimpleAuthenticator.login(this.domain, this.username, this.password, this.force);
                this.force = false;

                this.username = undefined;
                this.password = undefined;

                this.notify(this.$t("login_success"), "", "success");
            } catch (err) {
                window.localStorage.removeItem("username");

                if (err.message === SaySimpleAuthenticator.SaySimpleAuth.AUTHENTICATION_ALREADY_LOGGED_IN) {
                    if (confirm(this.$t("login_confirm_session_remove"))) {
                        this.force = true;
                        await this.login();
                    }
                } else if (err.message === SaySimpleAuthenticator.SaySimpleAuth.AUTHENTICATION_INVALID_CREDENTIALS) {
                    this.invalid = true;
                    this.notify(this.$t("login_failed_title"), this.$t("login_failed"), "error");
                } else {
                    this.notify(this.$t("server_unavailable_title"), this.$t("server_unavailable"), "error");
                }
            }
        },
        async logout() {
            if (SaySimpleAuthenticator.isLoggedIn()) {
                await SaySimpleAuthenticator.logout();
            }
        },
        showInfo() {
            console.log(SaySimpleAuthenticator.getAuthorizer());
        },
        notify(title, text, type) {
            this.$notify({
                title,
                text,
                type,
            });
        },
    },
    mounted() {
        const domain = window.location.hostname;
        const sessionDomain = window.localStorage.getItem("domain");

        if (!domain && !sessionDomain) {
            this.allowed = false;
        }
        this.domain = domain || sessionDomain;
        window.localStorage.setItem("domain", this.domain);

        if (process.env.VUE_APP_ENVIRONMENT === "dev") {
            this.debug = true;
            this.username = process.env.VUE_APP_USER;
            this.password = process.env.VUE_APP_PASS;
        }

        window.addEventListener(SaySimpleAuthenticator.events.LOGGED_IN, () => {
            this.isLogged = true;
        });

        window.addEventListener(SaySimpleAuthenticator.events.LOGGED_OUT, () => {
            this.isLogged = false;
        });

        this.username = window.localStorage.getItem("username");
    },
};
</script>

// This will import the SCSS scoped to this component only
<style scoped src="@/assets/scss/style.scss" lang="scss"></style>
